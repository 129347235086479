<template>
  <div class="ListText">
    <Header></Header>
    <Nav :fromPath="fromPath"></Nav>
    <!-- 顶部 -->
    <!-- 主体部分 -->
    <div class="banner">
      <div class="left">
        <!-- <div class="zhanshi">
          <el-carousel indicator-position="none" style="width: 100%; height: 100%">
            <el-carousel-item>
              <img style="width: 100%; height: 100%" :src="headImg1" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="children">
          <p class="title">
            <em> {{ titleName }}</em>
          </p>
        </div>
        <div class="line">
          <img :src="headImg2" alt="" style="width: 100%" />
        </div> -->
        <div class="chidlenlist">
          <div class="top">
            <p>首页 > {{ titleName }} > {{ title }}</p>
          </div>

          <div class="content">
            <p class="hscheck" @click="hscheck()">
              <img src="../images/qiehuan.png" alt="" />
            </p>
            <div
              class="left"
              :style="this.typelist.length == 0 ? 'border-right: none;' : ''"
            >
              <p
                v-for="item in typelist"
                :key="item.id"
                @click="changeType(item.menuName, item.menuId)"
                :class="classify == item.menuId ? 'active' : ''"
              >
                {{ item.menuName }}({{item.contentNum}})
                <img v-show="classify == item.menuId" src="../images/zzsr33.png" alt="" />
              </p>
            </div>
            <div class="right" v-if="showstyle == 2">
              <div
                v-for="(item, index) in viewslist"
                :key="index"
                @click="changePage(item)"
                class="list"
              >
                <img :src="item.imgUrl" alt="" />
                <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                  {{ item.title }}
                </p>
              </div>
              <div class="fenyeqi" v-show="total / 4 > 1">
                <el-pagination
                  style="text-align: end"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="4"
                  layout="prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
            <div class="right" v-if="showstyle == 1">
              <div
                class="listitem"
                v-on:mouseover="changeActive($event, index)"
                v-on:mouseout="removeActive($event)"
                v-for="(item, index) in viewslist"
                :key="item.id"
                @click="changePage(item)"
              >
                <div class="zuo">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.content }}</p>
                </div>
                <div class="you">
                  <p class="p1">{{ item.createTime }}</p>
                  <div @click="changePage(item)">
                    <img v-if="show != index" src="../images/zzsr44.png" alt="" id="s1" />
                    <div class="yyqx" v-if="show == index" @click="changePage(item)">
                      详情
                    </div>
                  </div>
                </div>
              </div>
              <div class="fenyeqi" v-show="total / 8 > 1">
                <el-pagination
                  style="text-align: end"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page.sync="currentPage1"
                  :page-size="8"
                  layout="prev, pager, next"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
// 1、新闻公告、艺术空间、你好作家、馆校合作   样式一样
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Nav from "../components/nav.vue";
import apinavmenu from "../api/api_navmenu";

export default {
  name: "ListText",
  data() {
    return {
      currentPage1: 1,
      titleName: "",
      fromPath: "",
      show: 999,
      pageNum: 1,
      pageSize: 8,
      viewslist: "",
      total: 0,
      headImg1: "",
      headImg2: "",
      nowType: "",
      typelist: [
        {
          title: "全部",
          id: "",
        },
      ],
      showstyle: 1,
      classify: "",
    };
  },
  components: {
    Header,
    Footer,
    Nav,
  },
  watch: {
    $route(to, from) {
    
    },
  },
  created() {
    this.classify = this.$route.query.classify;
    this.title = this.$route.query.title;
    this.titleName = this.$route.query.titleName;
    if (this.$route.query.classify == undefined) {
      apinavmenu
        .getContentList({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          menuId: this.$route.query.menuId,
        })
        .then((res) => {
          this.viewslist = res.data.rows;
          this.total = res.data.total;
        });
    } else {
      apinavmenu
        .getContentList({
          classify: this.$route.query.classify,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          menuId: "",
        })
        .then((res) => {
          this.viewslist = res.data.rows;
          this.total = res.data.total;
        });
    }
    apinavmenu
      .getClassify({
        menuId: this.$route.query.menuId,
      })
      .then((res) => {
        this.typelist = res.data;
      });
  },
  methods: {
    hscheck() {
      if (this.showstyle == 1) {
        this.showstyle = 2;
      } else {
        this.showstyle = 1;
      }
    },
    handleSizeChange(val) {
   
    },
    handleCurrentChange(val) {
     
      this.pageNum = val;
      apinavmenu
        .getContentList({
          classify: this.classify,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          menuId: "",
        })
        .then((res) => {
          this.viewslist = res.data.rows;
          this.total = res.data.total;
        });
    },
    changeActive($event, index) {
      $event.currentTarget.className = "liactive";
      this.show = index;
    },
    removeActive($event) {
      $event.currentTarget.className = "listitem";
    },
    changeType(title, classify) {
      this.pageNum = 1;
      this.currentPage1 = 1;
      this.classify = classify;
      this.title = title;
      apinavmenu
        .getContentList({
          classify: this.classify,
          pageNum: 1,
          pageSize: 8,
          menuId: "",
        })
        .then((res) => {
          this.viewslist = res.data.rows;
          this.total = res.data.total;
        });
    },
    changePage(item) {
      this.$router.push({
        path: "detail",
        query: {
          title: this.$route.query.titleName,
          title1: item.title,
          contentType: item.contentType,
          articleId: item.contentId,
        },
      });
    },
  },
};
</script>
<style scope>
::v-deep .fenyeqi .el-pagination {
  text-align: end !important;
}
.yyqx {
  color: #fff;
  width: 55px !important;
    height: 23px !important;
    line-height: 23px;
    background-color: #ae703e;
    font-size: 7px !important;
    margin-left: 25%;
}
.ListText {
  width: 100%;
  height: 100%;
  background-color: #fff;
  cursor: pointer;
}
/* 内容 */
.ListText .banner {
  width: 100%;
  min-height: 700px;
  height: auto;
  margin: 0px auto 40px;
  display: flex;
  padding-bottom: 100px;
}
.news img {
  width: 200px;
  height: 80px;
  margin-left: 39%;
  margin-top: -23%;
}
.ListText .banner .left {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.ListText .banner .left .zhanshi {
  width: 100%;
  height: 300px;
}
.ListText .banner .left .zhanshi .el-carousel__container {
  height: 100%;
}
.ListText .banner .left .children .title {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 4px;
  color: #000000;
}
.ListText .liactive {
  box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.08);
  -o-box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, 0.1);
}
.ListText .liactive {
  width: calc(100% - 40px);
  padding: 20px;
  /* height: 130px; */
  height: 49px;
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  display: flex;
  margin-bottom: 10px;
  margin-top: -5px;
  align-items: center;
}
.ListText .liactive .zuo {
  width: 80%;
}
.ListText .liactive .zuo h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "MicrosoftYaHei";
}
.ListText .liactive .zuo p {
  color: #999999;
  font-size: 14px;
  line-height: 25px;
}
.ListText .liactive .you {
 display: flex;
    width: 20%;
    text-align: center;
    color: #aaaaaa;
    flex-direction: column;
    justify-content: center
}
.ListText .liactive .you .p1 {
  font-size: 12px !important;
  font-size: 28px;
  /* margin: 25px 0px; */
}
.ListText .banner .left .children .title em {
  display: inline-block;
  width: auto;
  height: 50px;
  margin-top: 10px;
  text-align: center;
  font-style: normal;
  border-bottom: 1px solid #000;
}
.ListText .banner .left .line {
  width: 100%;
  height: 60px;
}
.ListText .banner .left .chidlenlist {
  width: 100%;
  min-height: 500px;
  background-color: #fff;
}
.ListText .banner .left .chidlenlist .top {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eeeeee;
}

.ListText .banner .left .chidlenlist .hscheck img {
  width: 20px;
  position: absolute;
  right: 0px;
  top: -28px;
}
.ListText .banner .left .chidlenlist .top p {
  width: 1200px;
  margin-left: calc((100% - 1200px) / 2);
  margin: 0px auto;
  color: #999;
  font-size: 14px;
  line-height: 50px;
}
.ListText .banner .left .chidlenlist .content {
  width: 1200px;
  min-height: 400px;
  margin-left: calc((100% - 1200px) / 2);
  border-top: 1px solid #eeeeee;
  margin-top: 50px;
  display: flex;
  position: relative;
}
.ListText .banner .left .chidlenlist .content .left {
  width: 30%;
  min-height: 400px;
  height: 100%;
  border-right: 1px solid #eeeeee;
}
.ListText .banner .left .chidlenlist .content .left p {
  height: 50px;
  line-height: 50px;
  color: #666;
  border-bottom: 1px solid #eeeeee;
  padding: 0px 20px;
  margin: 0px;
}

.ListText .banner .left .chidlenlist .content .left .active {
  color: #ae703e;
}
.ListText .banner .left .chidlenlist .content .left img {
  float: right;
  margin-top: 15px;
}
.ListText .banner .left .chidlenlist .content .right {
  width: 70%;
  height: 100%;
  padding: 30px;
}

.ListText .listitem {
  width: calc(100% - 40px);
  padding: 20px;
  /* height: 130px; */
  height: 49px;
  background-color: #ffffff;
  border: solid 1px #eeeeee;
  display: flex;
  margin-bottom: 10px;
  cursor: pointer;
  align-items: center;
}
.ListText .listitem .zuo {
  width: 80%;
}
.ListText .listitem .zuo h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #333333;
  font-family: "MicrosoftYaHei";
}
.ListText .listitem .zuo p {
  color: #999999;
  font-size: 14px;
  line-height: 25px;
}
.ListText .listitem .you {
 display: flex;
    width: 20%;
    text-align: center;
    color: #aaaaaa;
    flex-direction: column;
    justify-content: center
}
.ListText .listitem .you .p1 {
  font-size: 12px !important;
  font-size: 28px;
  /* margin: 25px 0px; */
}
::v-deep .fenyeqi .el-pagination button:hover{
  color: rgb(94, 91, 91);
}
::v-deep .fenyeqi .el-pager .btn-prev .number{
  color: #999999 !important;
}
::v-deep .fenyeqi .el-pager li.active {
    color: #ae703e;
    cursor: pointer}
.ListText .list {
  width: 25%;
  display: inline-block;
  text-align: center;
}
.ListText .list img {
     width: 174px;
    height: 146px;
}
</style>
